import * as React from "react"

export const ErrorNotification = ({ error, short }: { error: Error | string | undefined; short?: boolean }) =>
  error === undefined ? null : (
    <div className="error">
      {error instanceof Error ? (
        <>
          <h3>{`${error.name}: ${error.message}`}</h3>
          {short !== true && error.stack !== undefined && <pre>{error.stack}</pre>}
        </>
      ) : (
        <h3>{error}</h3>
      )}
    </div>
  )
