import * as React from "react"
import { Route, Switch } from "wouter"
import { render as ReactDOMRender } from "react-dom"

import { RedirectPage } from "./redirect"

import * as config from "./config"
import { ErrorNotification } from "./error-notification"

import { ClientMessage } from "../../gameserver-rs/bindings/ClientMessage"
import { ServerMessage } from "../../gameserver-rs/bindings/ServerMessage"
import { Entity } from "../../gameserver-rs/bindings/Entity"
import { StaticObject } from "../../gameserver-rs/bindings/StaticObject"

type GameStateServerMessage = Extract<ServerMessage, { type: "GameStateEntities" | "GameStateStatic" }>

type UsersConnectedServerMessage = Extract<ServerMessage, { type: "UsersConnected" }>
;(window as any).debug_canvas = false
;(window as any).debug_ws = false
;(window as any).debug_window_events = false

interface IPUser {
  type: "Ip"
  name: string
}

interface DiscordUser {
  type: "Discord"
  id: string
  name: string
  avatar_id: string
}

interface GithubUser {
  type: "Github"
  name: string
  avatar_url: string
}

// TODO should we abolish this and rely on the rust types to be in sync with auth server?
type User = IPUser | DiscordUser | GithubUser

type SettingAction = "Simulate" | "AutoReconnect"

const logout = async () => {
  const url = config.API_URL + "/auth/logout"
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (200 <= response.status && response.status < 300) {
    return response.text()
  }

  throw new Error(`[${response.status} ${response.statusText}] when logging out from ${response.url}`)
}

const verify = async () => {
  const url = config.API_URL + "/auth/verify"
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (401 === response.status) {
    return "UNAUTHORIZED" as const
  }

  if (200 <= response.status && response.status < 300) {
    const user: User = await response.json()
    return user
  }

  throw new Error(`[${response.status} ${response.statusText}] when getting token from ${response.url}`)
}

const doIpLogin = async () => {
  const url = config.API_URL + "/auth/ip-login"
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ name: `Anon${String(Math.floor(Math.random() * 999)).padStart(3, "0")}` }),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (401 === response.status) {
    return "UNAUTHORIZED" as const
  }

  if (200 <= response.status && response.status < 300) {
    const user: User = await response.json()
    return user
  }

  throw new Error(`[${response.status} ${response.statusText}] when getting token from ${response.url}`)
}

interface Log {
  key: number
  time: Date
  type: "CHAT" | "SERVER_ANNOUNCE" | "INFO_HAPPY" | "INFO_NEUTRAL" | "WARNING" | "ERROR"
  message: string
}

let messageKey = 0

const createLog = (
  type: "CHAT" | "SERVER_ANNOUNCE" | "INFO_HAPPY" | "INFO_NEUTRAL" | "ERROR",
  message: string
): Log => ({
  key: messageKey++,
  time: new Date(),
  type,
  message,
})

// Enable auto retry connect
const AUTO_CONNECT = false

function Connect({
  connected,
  pushLog,
  onConnection: onSuccessfulConnection,
}: {
  connected: boolean
  pushLog: (log: Log) => void
  onConnection: (ws: WebSocket) => void
}) {
  const [connecting, setConnecting] = React.useState(false)

  function connect() {
    setConnecting(true)
    const webSocket = new WebSocket(config.GAME_SERVER_URL)
    webSocket.onerror = () => {
      pushLog(createLog("ERROR", "Could not connect to server."))
      setConnecting(false)
    }
    webSocket.onopen = () => {
      pushLog(createLog("INFO_HAPPY", "Connected."))
      setConnecting(false)
      onSuccessfulConnection(webSocket)
    }
  }

  React.useEffect(() => {
    if (AUTO_CONNECT) {
      const handle = setInterval(connect, 1000)
      return () => {
        clearInterval(handle)
      }
    }
    return () => undefined
  }, [])

  if (connected) {
    return <p className="connect">Connected</p>
  }

  if (connecting) {
    return <p className="connect">Connecting...</p>
  }

  return (
    <a className="connect" onClick={connect}>
      Connect
    </a>
  )
}

const userAvatarUrl = (user: User) => {
  switch (user.type) {
    case "Discord":
      return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar_id}.png`
    case "Github":
      return user.avatar_url
    case "Ip":
      return `https://ui-avatars.com/api/?name=${user.name}&format=svg&background=random`
  }
  return `https://ui-avatars.com/api/?name=??&format=svg&background=random`
}

function Avatar({ user }: { user: User }) {
  const [error, setError] = React.useState(false)
  return (
    <img
      key="user-avatar"
      className="user-avatar"
      src={userAvatarUrl(user)}
      onError={event => {
        console.log("Avatar error", event)
        ;(event.target as HTMLImageElement).className = "user-avatar user-avatar-error"
        if (!error) {
          ;(
            event.target as HTMLImageElement
          ).src = `https://ui-avatars.com/api/?name=${user.name}&format=svg&background=random`
          return setError(true)
        }
      }}
    />
  )
}

function SubName({ user }: { user: User }) {
  switch (user.type) {
    case "Discord":
    // return <span className="user-info-subname">{`#${}`}</span>
    default:
      return null
  }
}

function UserInfo({ user, size, children }: { user: User; size?: "small"; children?: React.ReactNode }) {
  return (
    <div className={`${size === "small" ? "small" : ""} user`}>
      <Avatar user={user} />
      <div className="user-info">
        <strong className="user-info-name">{`${user.name}  `}</strong>
        <SubName user={user} />
      </div>
      {children}
    </div>
  )
}

function LoggedInUser({ user, logout }: { user: User; logout: () => void }) {
  return (
    <UserInfo user={user}>
      <a className="user-logout" onClick={logout}>
        Log
        <br />
        Out
      </a>
    </UserInfo>
  )
}

function Logs({ logs }: { logs: Log[] }) {
  return (
    <div className="logs">
      {logs.map(log => (
        <p
          key={log.key}
          className={`log ${
            log.type === "ERROR"
              ? "log-error"
              : log.type === "INFO_HAPPY"
              ? "log-info-happy"
              : log.type === "INFO_NEUTRAL"
              ? "log-info-neutral"
              : log.type === "SERVER_ANNOUNCE"
              ? "log-announce"
              : "log-chat"
          }`}
        >
          {log.message}
        </p>
      ))}
    </div>
  )
}

class Game {
  entities: Entity[]
  statics: StaticObject[]

  // TODO Players as a dictionary

  constructor() {
    this.entities = []
    this.statics = []
  }

  simulate(deltaTime: number) {
    for (const e of this.entities) {
      e.position[0] += e.velocity[0] * deltaTime
      e.position[1] += e.velocity[1] * deltaTime
    }
  }

  handleEvent(event: GameStateServerMessage) {
    if ((window as any).debug_ws) console.log(event)
    switch (event.type) {
      case "GameStateStatic": {
        this.statics = event.statics
        return
      }
      case "GameStateEntities": {
        this.entities = event.entities
        return
      }
    }
  }
}

const WORLD_WIDTH = 800
const WORLD_HEIGHT = 500

let CANVAS_WIDTH: number
let CANVAS_HEIGHT: number
let DEVICE_PIXEL_RATIO: number
let CANVAS_CTX_WIDTH: number
let CANVAS_CTX_HEIGHT: number
let CANVAS_CTX_SCALE: number

const resizeCanvas = () => {
  CANVAS_WIDTH = Math.floor(window.innerWidth)
  CANVAS_HEIGHT = Math.floor(CANVAS_WIDTH / 1.6)

  if (window.innerHeight < CANVAS_HEIGHT) {
    CANVAS_HEIGHT = Math.floor(window.innerHeight)
    CANVAS_WIDTH = Math.floor(CANVAS_HEIGHT * 1.6)
  }

  DEVICE_PIXEL_RATIO = window.devicePixelRatio
  CANVAS_CTX_WIDTH = Math.floor(CANVAS_WIDTH * DEVICE_PIXEL_RATIO)
  CANVAS_CTX_HEIGHT = Math.floor(CANVAS_HEIGHT * DEVICE_PIXEL_RATIO)
  CANVAS_CTX_SCALE = CANVAS_CTX_WIDTH / WORLD_WIDTH

  if ((window as any).debug_canvas) {
    console.log("CANVAS_WIDTH", CANVAS_WIDTH)
    console.log("CANVAS_HEIGHT", CANVAS_HEIGHT)
    console.log("DEVICE_PIXEL_RATIO", DEVICE_PIXEL_RATIO)
    console.log("CANVAS_CTX_WIDTH", CANVAS_CTX_WIDTH)
    console.log("CANVAS_CTX_HEIGHT", CANVAS_CTX_HEIGHT)
  }
}
resizeCanvas()

function Connected({
  gameState,
  send,
  pushLog,
  disconnect,
}: {
  gameState: React.MutableRefObject<Game>
  send: (msg: ClientMessage) => void
  pushLog: (log: Log) => void
  disconnect: () => void
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null)
  const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null)
  const animationRef = React.useRef<number | null>(null)
  const settingsRef = React.useRef({ simulate: false })
  // const pixelRatio = window.devicePixelRatio || 1;
  const timestamp = React.useRef<number | undefined>(undefined)

  const step = (time: DOMHighResTimeStamp) => {
    if (timestamp.current !== undefined && settingsRef.current.simulate) {
      const deltaTime = (time - timestamp.current) / 1000
      gameState.current.simulate(deltaTime) // Somehow it looks uglier when we simulate
    }
    timestamp.current = time

    const ctx = canvasCtxRef.current
    if (!ctx) {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
      return
    }

    ctx.fillStyle = "black"
    ctx.fillRect(0, 0, WORLD_WIDTH, WORLD_HEIGHT)

    ctx.fillStyle = "grey"
    for (const e of gameState.current.statics) {
      ctx.fillRect(e.position[0], e.position[1], e.size[0], e.size[1])
    }

    for (const e of gameState.current.entities) {
      ctx.fillStyle = `rgb(${e.color[0]},${e.color[1]},${e.color[2]})`
      ctx.fillRect(e.position[0], e.position[1], e.size[0], e.size[1])
      // TODO players in game state
      ctx.font = `${Math.floor(e.size[0] * 2)}px monospace`
      ctx.textAlign = "center"
      ctx.fillText(`#${e.id}`, e.position[0], e.position[1] - e.size[0] / 2)
    }

    animationRef.current = requestAnimationFrame(step)
  }

  React.useEffect(() => {
    const mousemap: Record<number, "Primary" | "Secondary"> = {
      0: "Primary",
      2: "Secondary",
    } as const
    const onMousedown = (event: MouseEvent) => {
      if ((window as any).debug_window_events) console.log(event.button)

      if (!canvasRef.current) return
      const action = mousemap[event.button]
      if (action !== undefined) {
        const rect = canvasRef.current.getBoundingClientRect()
        const x = ((event.clientX - rect.left) * DEVICE_PIXEL_RATIO) / CANVAS_CTX_SCALE
        const y = ((event.clientY - rect.top) * DEVICE_PIXEL_RATIO) / CANVAS_CTX_SCALE

        // TODO prevent right click menu if we are within the world
        send({
          type: "KeyDown",
          key: { type: action, position: [x, y] },
        })
      }
    }
    addEventListener("mousedown", onMousedown)

    const onMouseup = (event: MouseEvent) => {
      if ((window as any).debug_window_events) console.log(event.button)

      if (!canvasRef.current) return
      const action = mousemap[event.button]
      if (action !== undefined) {
        const rect = canvasRef.current.getBoundingClientRect()
        const x = ((event.clientX - rect.left) * DEVICE_PIXEL_RATIO) / CANVAS_CTX_SCALE
        const y = ((event.clientY - rect.top) * DEVICE_PIXEL_RATIO) / CANVAS_CTX_SCALE

        send({
          type: "KeyUp",
          key: { type: action, position: [x, y] },
        })
      }
    }
    addEventListener("mouseup", onMouseup)

    const globalControl: Record<string, SettingAction> = { p: "Simulate", u: "AutoReconnect" }
    const keymap: Record<string, "Up" | "Left" | "Down" | "Right" | "Jump"> = {
      w: "Up",
      a: "Left",
      s: "Down",
      d: "Right",
      " ": "Jump",
    } as const
    const onKeydown = (event: KeyboardEvent) => {
      if ((window as any).debug_window_events) console.log(event)

      const input: HTMLInputElement = document.getElementById("send-message") as HTMLInputElement
      if (event.key === "Enter") {
        input.focus()
      }
      const action = keymap[event.key]
      if (action !== undefined) {
        send({
          type: "KeyDown",
          key: { type: action },
        })
      }
      if (globalControl[event.key] !== undefined) {
        if (globalControl[event.key] == "Simulate") {
          settingsRef.current = { ...settingsRef.current, simulate: !settingsRef.current.simulate }
          pushLog(
            createLog("INFO_NEUTRAL", `Turning ${settingsRef.current.simulate ? "on" : "off"} physics simulation.`)
          )
        }
        // TODO hotkey for auto reconnect
      }
    }
    addEventListener("keydown", onKeydown)

    const onKeyup = (event: KeyboardEvent) => {
      const action = keymap[event.key]
      if (action !== undefined) {
        send({
          type: "KeyUp",
          key: { type: action },
        })
      }
    }
    addEventListener("keyup", onKeyup)

    const initializeCanvas = () => {
      resizeCanvas()
      const canvas = canvasRef?.current
      if (canvas == null) {
        console.log("no canvas")
        return
      }
      canvas.style.width = `${CANVAS_WIDTH}px`
      canvas.style.height = `${CANVAS_HEIGHT}px`
      canvas.width = CANVAS_CTX_WIDTH
      canvas.height = CANVAS_CTX_HEIGHT
      const ctx = canvas?.getContext("2d")
      if (ctx == null) {
        return
      }
      ctx.scale(CANVAS_CTX_SCALE, CANVAS_CTX_SCALE)
      canvasCtxRef.current = ctx
    }
    initializeCanvas()
    addEventListener("resize", initializeCanvas)

    animationRef.current = requestAnimationFrame(step)
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
      removeEventListener("mousedown", onMousedown)
      removeEventListener("keydown", onKeydown)
      removeEventListener("keyup", onKeyup)
      removeEventListener("resize", initializeCanvas)
    }
  }, [])

  return (
    <>
      <input
        id="send-message"
        type="text"
        className="send-message"
        onKeyUp={event => event.stopPropagation()}
        onKeyDown={event => {
          const input: HTMLInputElement = document.getElementById("send-message") as HTMLInputElement
          if (event.key === "Enter") {
            if (input.value !== "") {
              send({
                type: "Chat",
                message: input.value,
              })
              input.value = ""
            }
            input.blur()
          }
          if (event.key === "Escape") {
            input.blur()
          }
          event.stopPropagation()
        }}
      />
      <a className="disconnect" onClick={disconnect}>
        Disconnect
      </a>
      <canvas className="canvas" ref={canvasRef}></canvas>
    </>
  )
}

function LoggedIn({ user, onLogout }: { user: User; onLogout: () => void }) {
  const gameState = React.useRef(new Game())

  const [webSocket, setWebSocket] = React.useState<WebSocket | undefined>(undefined)
  const [logs, setLogs] = React.useState<Log[]>([])
  const [users, setUsers] = React.useState<User[]>([])

  const pushLog = (log: Log) => setLogs(logs => [log, ...logs].slice(0, 40))

  const handleLogout = () =>
    logout().then(() => {
      webSocket?.close()
      setWebSocket(undefined)
      onLogout()
    })

  const handleGameUpdate = (msg: GameStateServerMessage) => {
    gameState.current.handleEvent(msg)
  }

  const handleUsersConnectedUpdate = (msg: UsersConnectedServerMessage) => {
    setUsers(msg.users.filter(otherUser => otherUser.name !== user.name))
  }

  const onConnection = (ws: WebSocket) => {
    ws.onerror = err => {
      console.log("WebSocket error", err)
      pushLog(createLog("ERROR", "Websocket error."))
    }
    ws.onclose = closeEvent => {
      if (closeEvent.code === 1008 && closeEvent.reason === "NOT_LOGGED_IN") {
        pushLog(createLog("ERROR", "Failed to connect. No logged in user."))
      } else if (closeEvent.code === 1008 && closeEvent.reason === "UNAUTHORIZED") {
        pushLog(createLog("ERROR", "Failed to authorize."))
      } else if (closeEvent.code === 1008 && closeEvent.reason === "ALREADY_JOINED") {
        pushLog(createLog("ERROR", "You have already joined the room."))
      } else if (closeEvent.code === 1006) {
        pushLog(createLog("INFO_NEUTRAL", "Disconnected."))
      } else {
        console.log(closeEvent)
        pushLog(createLog("ERROR", `Lost connection to server with code ${closeEvent.code}.`))
      }
      setWebSocket(undefined)
      setUsers([])
    }
    ws.onmessage = message => {
      const parsed: ServerMessage = JSON.parse(message.data)
      switch (parsed.type) {
        case "Announce":
          pushLog(createLog("SERVER_ANNOUNCE", parsed.message))
          return
        case "Chat":
          pushLog(createLog("CHAT", `${parsed.sender}: ${parsed.message}`))
          return
        case "GameStateStatic":
        case "GameStateEntities":
          handleGameUpdate(parsed)
          return
        case "UsersConnected":
          handleUsersConnectedUpdate(parsed)
          return
      }
      console.warn("Unknown message from server", parsed)
    }
    setWebSocket(ws)
  }

  const connected = webSocket !== undefined && webSocket.readyState === webSocket.OPEN

  const send = (message: ClientMessage) => {
    webSocket?.send(JSON.stringify(message))
  }
  const disconnect = () => {
    setUsers([])
    webSocket?.close()
  }
  return (
    <div>
      {connected ? (
        <Connected gameState={gameState} send={send} pushLog={pushLog} disconnect={disconnect} />
      ) : (
        <Connect connected={connected} pushLog={pushLog} onConnection={onConnection} />
      )}
      <Logs logs={logs} />
      <div className="user-side-bar">
        <LoggedInUser user={user} logout={handleLogout} />
        {users.map((user, i) => (
          <UserInfo key={i} user={user} size="small" />
        ))}
      </div>
    </div>
  )
}

// TODO move this to module
function createOauthState(provider: "DISCORD" | "GITHUB") {
  const state = `${provider}:${String(Math.floor(Math.random() * 100000000000))}`
  localStorage.setItem("oauth_state", state)
  return { state }
}

function MainPage() {
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [loggingIn, setLoggingIn] = React.useState(true)
  const [error, setError] = React.useState<Error | string | undefined>(undefined)

  const handleLoginError = (error: any) => {
    console.log(error)
    setError("Could not reach authentication server.")
  }
  React.useEffect(() => {
    verify()
      .then(user => (user === "UNAUTHORIZED" ? setUser(undefined) : setUser(user)))
      .catch(handleLoginError)
      .then(() => setLoggingIn(false))
  }, [])

  const ipLogin = async () => {
    setLoggingIn(true)
    doIpLogin()
      .then(user => (user === "UNAUTHORIZED" ? setUser(undefined) : setUser(user)))
      .catch(handleLoginError)
      .then(() => setLoggingIn(false))
  }

  if (loggingIn) {
    return <></>
  }

  if (user) {
    return <LoggedIn user={user} onLogout={() => setUser(undefined)} />
  }

  return (
    <div className="log-in">
      Log in
      <br />
      <a
        onClick={() => {
          const { state } = createOauthState("DISCORD")
          window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${
            config.DISCORD_CLIENT_ID
          }&redirect_uri=${
            config.REDIRECT_URL
          }&scope=identify&response_type=code&response_mode=query&nonce=${Math.floor(
            Math.random() * 100000000000
          )}&state=${state}`
        }}
      >
        Discord
      </a>
      {config.isRelease && (
        <>
          <br />
          <a
            onClick={() => {
              const { state } = createOauthState("GITHUB")
              window.location.href = `https://github.com/login/oauth/authorize?client_id=${config.GITHUB_CLIENT_ID}&redirect_uri=${config.REDIRECT_URL}&scope=read:user&state=${state}`
            }}
          >
            Github
          </a>
        </>
      )}
      {!config.isRelease && (
        <>
          <br />
          <a onClick={ipLogin}>IP</a>
        </>
      )}
      <ErrorNotification error={error} short={true} />
    </div>
  )
}

function Application() {
  return (
    <Switch>
      <Route path="/redirect">
        <RedirectPage />
      </Route>
      <Route path="/">
        <MainPage />
      </Route>
    </Switch>
  )
}

ReactDOMRender(<Application />, document.getElementById("react-root"))
