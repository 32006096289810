import * as React from "react"

import * as config from "./config"
import { useLocation } from "wouter"
import { ErrorNotification } from "./error-notification"

const login = async (code: string, oauthProvider: string) => {
  const url = config.API_URL + "/auth/login"
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      code,
      redirect_url: config.REDIRECT_URL,
      provider: oauthProvider,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (200 <= response.status && response.status < 300) {
    return response.text()
  }

  throw new Error(`[${response.status} ${response.statusText}] when getting token from ${response.url}`)
}

export function RedirectPage() {
  const [error, setError] = React.useState<Error | undefined>(undefined)
  const [_, setLocation] = useLocation()

  React.useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code")
    if (!code) {
      setLocation("/")
    } else {
      const urlState = new URLSearchParams(window.location.search).get("state")
      const state = localStorage.getItem("oauth_state")
      if (urlState !== state) {
        setLocation("/")
      }
      const oauthProvider = state!.split(":")[0]
      login(code, oauthProvider)
        .then(() => setLocation("/"))
        .catch(error => setError(error))
    }
  }, [])

  if (error) {
    return <ErrorNotification error={error} short={true} />
  }

  return <div className="logging-in">Logging in...</div>
}
