export const DISCORD_CLIENT_ID = "528891626295132161"
export const GITHUB_CLIENT_ID = "6b9072f5c1e40b155a66"

export const isRelease = window.location.hostname.endsWith("xyz")
const c = <T, F>(release: T, local: F): T | F => (isRelease ? release : local)

const TLD = c("xyz", "local")

export const GAME_SERVER_URL = `wss://tiseno.${TLD}/ws`
export const API_URL = `https://tiseno.${TLD}`
export const REDIRECT_URL = `https://tiseno.${TLD}/redirect`
